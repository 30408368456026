/* eslint-disable jsx-a11y/alt-text */
import GocTL from "../images/goc-left.png";
import GocBR from "../images/goc-right.png";
import LeftBorder from "../images/left-border.png";
import RightBorder from "../images/left-border.png";
import Mouse from "../images/mouse-grap.png";
import { isMobile } from "react-device-detect";

const PageCommon = (props) => {
  return (
    <div className="bg-page page-common relative">
      {props?.imageTL && (
        <img src={GocTL} className="absolute top-3 left-3 w-[100px] z-10" />
      )}
      {!isMobile && props?.imageBR && (
        <img src={LeftBorder} className="absolute bottom-0 left-0 z-10" />
      )}
      {!isMobile && props?.imageTL && (
        <img src={RightBorder} className="absolute bottom-0 right-0  z-10" />
      )}
      {props.children}

      {props?.imageBR && (
        <img src={GocBR} className="absolute bottom-3 right-3 w-[100px] z-10" />
      )}
      {/* {props?.pageLeft && (
        <div className="w-[30px] h-[30px]  rounded-full dot-doc-left enabled">
          <img src={Mouse} className="absolute bottom-0 right-0  z-10" />
        </div>
      )} */}
      {!props?.isEndPage && (isMobile || props?.pageRight) && (
        <div className="w-[30px] h-[30px] border-2 border-cyan-100 rounded-full dot-doc-right enabled flex items-center justify-center">
          {/* <img src={Mouse} className="absolute bottom-0 right-0  z-10" /> */}
          <div className="w-[20px] h-[20px] bg-[#fff] rounded-full"></div>
        </div>
      )}
      {/* {props?.pageLeft && (
        <div className="w-[30px] h-[30px] border-2 border-cyan-100 rounded-full dot-doc-left enabled flex items-center justify-center">
          <img src={Mouse} className="absolute bottom-0 right-0  z-10" />
          <div className="w-[20px] h-[20px] bg-[#fff] rounded-full"></div>
        </div>
      )} */}
    </div>
  );
};

export default PageCommon;
