import { Bounce, JackInTheBox } from "react-awesome-reveal";
import styles from "./First5Page.module.css";

const Page3 = () => {
  return (
    <div className="relative size-full">
      <JackInTheBox delay={200}>
        <p className={styles.page3__title}>
          Chapter 2
          <br />
          One True OG
        </p>
      </JackInTheBox>
      <img
        className=" absolute bottom-0 z-0"
        src="/images/sitting-man.png"
        width={720}
        height={978}
        alt=""
      />
      <Bounce delay={800}>
        <div className="text-center text-white text-lg px-12 mt-20 md:text-xl font-squada z-10 relative md:px-6 md:mt-24 !leading-none">
          The OG meme spread like wildfire once it was published captivating the
          hearts and screens of netizens everywhere <br />
          Its creation became the blueprint for all memes and shitpostings to
          come
          <br />
          forever etching his role in the annals of meme history
          <br />
          <br />
          Today, it is hailed as a pioneer
          <br />
          forever remembered as the one who started it all
          <br />
          The True OG
        </div>
      </Bounce>
    </div>
  );
};
export default Page3;
