/* eslint-disable jsx-a11y/alt-text */
import BgPage11 from "../../images/bg-page-11.png";
import Rocket from "../../images/roket.gif";
const Page11 = () => {
  return (
    <div className="page-common relative flex items-center justify-end">
      <img src={BgPage11} className="max-h-[100%] relative z-[5]" />
      <img src={Rocket} className="max-h-[100%] absolute z-[1] scale-150" />
    </div>
  );
};

export default Page11;
