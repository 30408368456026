import "@fontsource/bebas-neue";
import "@fontsource/galada";
import "@fontsource/squada-one";
import "@fontsource/allura";

import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import "turn.js";

import "./styles.css";

import { createRoot } from "react-dom/client";
import Turn from "./Turn.js";
import PageCommon from "./componants/PageCommon.js";
import OpeningCover from "./componants/pages/OpeningCover.js";
import Page1 from "./componants/pages/Page1.js";
import Page10 from "./componants/pages/Page10.js";
import Page11 from "./componants/pages/Page11.js";
import Page12 from "./componants/pages/Page12.js";
import Page13 from "./componants/pages/Page13.js";
import Page14 from "./componants/pages/Page14.js";
import Page2 from "./componants/pages/Page2.js";
import Page3 from "./componants/pages/Page3.js";
import Page4 from "./componants/pages/Page4.js";
import Page5 from "./componants/pages/Page5.js";
import Page9 from "./componants/pages/Page9.js";
import useWindowSize from "./utils/index.js";
import { Fade } from "react-awesome-reveal";

const optionsDefault = {
  width: 942,
  height: 600,
  acceleration: true,
  gradients: true,
};

const App = () => {
  const [options, setOptions] = useState(optionsDefault);
  const [width, height] = useWindowSize();
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  useEffect(() => {
    if (width > 0 && height > 0 && isFirstLoading) {
      let widthSet = width > 942 ? 942 : width - 10;
      let heightSet = width > 942 ? 600 : height * 0.85;
      if (isMobile) {
        setOptions((s) => ({
          ...s,
          width: widthSet,
          height: heightSet,
          display: "single",
        }));
      } else {
        setOptions((s) => ({
          ...s,
          width: widthSet,
          height: heightSet,
          display: "double",
        }));
      }
      setIsFirstLoading(false);
    }
  }, [width, height, isFirstLoading]);

  useEffect(() => {
    var Stars = function (args) {
      if (args === undefined) args = {};
      var _scope = this;

      this.stars = [];
      this.vel = args.vel || 1;
      this.radius = args.radius || 1;
      this.alpha = 0.5;
      this.starsCounter = args.stars || 300;
      var center = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      };
      var canvas, context;
      this.init = function () {
        canvas = document.createElement("canvas");
        document.body.appendChild(canvas);
        canvas.style.position = "absolute";
        canvas.style.top = "0";
        context = canvas.getContext("2d");
        context.lineCap = "round";
        this.start();
        this.resize();
        //
        window.addEventListener("resize", this.resize.bind(this));
      };

      this.start = function () {
        this.stars = [];
        for (var i = 0; i < this.starsCounter; i++) {
          setTimeout(function () {
            _scope.stars.push(new Star());
          }, i * 1000);
        }
      };

      this.resize = function () {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        center.x = canvas.width / 2;
        center.y = canvas.height / 2;
      };

      this.animate = function () {
        window.requestAnimationFrame(this.animate.bind(this));
        this.render();
      };
      this.render = function () {
        context.fillStyle = "#1C1C1C";
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = "white";
        for (var i = 0; i < this.stars.length; i++) this.stars[i].update();
      };

      var Star = function () {
        this.x = center.x;
        this.y = center.y;
        this.init = function () {
          this.radius = Math.random() * _scope.radius;
          this.x = center.x;
          this.y = center.y;
          this.lineWidth = 0;
          this.vel = {
            x: Math.random() * 10 - 5,
            y: Math.random() * 10 - 5,
          };
        };
        this.update = function () {
          this.vel.x *= 1.05;
          this.vel.y *= 1.05;
          this.lineWidth += 0.035;
          this.x0 = this.x;
          this.y0 = this.y;
          this.x += this.vel.x;
          this.y += this.vel.y;
          this.draw();
          if (this.isDead()) this.init();
        };
        this.draw = function () {
          context.beginPath();
          context.moveTo(this.x0, this.y0);
          context.lineTo(this.x, this.y);
          context.lineWidth = this.lineWidth;
          context.stroke();
        };
        this.isDead = function () {
          return (
            this.x < 0 ||
            this.x > canvas.width ||
            this.y < 0 ||
            this.y > canvas.height
          );
        };
        this.init();
        return this;
      };
      this.init();
      this.animate();
      return this;
    };

    var _stars = new Stars();
  }, []);

  return (
    <div className="wrapper " id="wrapper">
      <div className="aspect">
        <div className="aspect-inner">
          {width > 0 && !isFirstLoading && (
            <Turn options={options} className="flipbook " id="flipbook">
              <div className="hard">
                <PageCommon pageRight={true}>
                  <OpeningCover />
                </PageCommon>
              </div>
              {!isMobile && (
                <div className="hard">
                  <PageCommon imageTL={true} pageLeft={true}>
                <div className="flex justify-between items-center h-full">
                      <div className="m-auto size-fit">
                        CA:0xcd841772fac6e43272e11e111259527a48a5f397
                      </div>
                    </div>
                </PageCommon>
                </div>
              )}

              <div className="page">
                <PageCommon imageBR={true} pageRight={true}>
                  <div className="flex flex-col gap-7 text-white text-lg md:text-xl font-allura h-full justify-center pl-2 md:pl-8 md:pr-4 font-light">
                    <Fade
                      className="md:tracking-wide"
                      delay={200}
                      cascade
                      damping={0.05}
                    >
                      “The richest man is the one with the most powerful
                      friends.”
                    </Fade>

                    <Fade
                      delay={200}
                      cascade
                      damping={0.3}
                      className="text-end pr-4 md:pr-1"
                    >
                      —Godfather
                    </Fade>
                  </div>
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageTL={true} pageLeft={true}>
                  <Page1 />
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageBR={true} pageRight={true}>
                  <Page2 />
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageTL={true} pageLeft={true}>
                  <Page3 />
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageBR={true} pageRight={true}>
                  <Page4 />
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageTL={true} pageLeft={true}>
                  <Page5 />
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageBR={true} pageRight={true}>
                  <Page9 />
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageTL={true} pageLeft={true}>
                  <Page10></Page10>
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageBR={true} pageRight={true}>
                  <Page11></Page11>
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageTL={true} pageLeft={true}>
                  <Page12></Page12>
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon imageBR={true} pageRight={true}>
                  <Page13></Page13>
                </PageCommon>
              </div>
              <div className="page">
                <PageCommon isEndPage={true}>
                  <Page14></Page14>
                </PageCommon>
              </div>
            </Turn>
          )}
        </div>
      </div>
    </div>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<App />);
