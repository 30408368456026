import styles from "./First5Page.module.css";
const OpeningCover = () => {
  return (
    <div className={styles.cover}>
      <div className="flex flex-col items-center leading-[0.85] mb-52">
        <span className={styles.cover__title1}>Original Meme</span>
        <span className={styles.cover__title2}>Godfather</span>
      </div>
      <span className={styles.cover__subtitle}>
        The world’s first meme in 1921
      </span>
    </div>
  );
};
export default OpeningCover;
