import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Zoom } from "react-awesome-reveal";

const Page14 = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="page-common relative p-8">
      <div
        className="flex flex-col gap-1 items-center justify-center h-full font-galada"
        data-aos="fade-top"
      >
        <Zoom delay={3500} className=" text-3xl md:text-[44px] text-shadow">
          Original Meme
        </Zoom>
        <Zoom delay={3500} className="md:text-[104.71px] text-6xl text-shadow ">
          Godfather
        </Zoom>
      </div>
      <div className="absolute bottom-[66px] text-center font-bebas w-full left-0">
        Copyright 2024 $OG Coin. All rights reserved.
      </div>
    </div>
  );
};

export default Page14;
