import { LINKS } from "../../constants";

const Page2 = () => {
  // let navigate = useNavigate();

  return (
    <div className=" size-full">
      <img
        className=" w-[330px] h-[430px] pl-8 pt-8 md:pt-12 md:pl-16 md:h-[500px] md:w-[400px]"
        src="/images/page2-article.png"
        width={468}
        height={671}
        alt=""
      />
      <a href={LINKS.NEWSPAPER} target="_blank" rel="noreferrer">
        <button className="bg-black ml-8 md:ml-16 mt-4 common-transition rounded-xl px-8 py-0.5 hover:scale-105 text-white text-2xl font-squada">
          Read more
        </button>
      </a>
    </div>
  );
};
export default Page2;
