import { Fade } from "react-awesome-reveal";

const Page12 = () => {
  return (
    <div className="page-common relative p-8">
      <div className="flex flex-col gap-1 items-center justify-center h-full ">
        <Fade
          cascade
          damping={0.2}
          className="text-[60px] sm:text-[80px] text-shadow font-galada text-white"
        >
          Tokenomics
        </Fade>
        <p className="flex flex-col gap-4 items-center mt-3 text-[18px] md:text-[16px] text-white">
          <div>Symbol: $OG</div>
          <div>Tax: 0/0</div>
          <div>Total Supply: 420,690,000,000</div>
          <div className="text-center">
            Contract Address:
            <br />
            0xcd841772fac6e43272e11e111259527a48a5f397
          </div>
        </p>
      </div>
    </div>
  );
};

export default Page12;
