import { Bounce, Fade, Zoom } from "react-awesome-reveal";
import styles from "./First5Page.module.css";

const Page1 = () => {
  return (
    <div className="h-full relative">
      <Bounce delay={200} className={styles.page1__title}>
        <p className="">
          Chapter 1
          <br />
          The Birth of OG
        </p>
      </Bounce>

      <div className="flex pl-8">
        <Zoom delay={800}>
          <p className="text-white md:text-lg leading-[1.1] pt-10 min-w-[150px]">
            Long ago, in 1921, a comic strip was published in the Wisconsin
            Octopus student satirical magazine with an exact caption that read
            “How You Think You Look When A Flashlight Is Taken VS How You Really
            Look”.
            <br />
            <br />
            It was the very first meme to be created; a stroke of genius,
            combining clever humour with relatable images similar to the meme
            format we know in today’s world as “Expectation vs Reality”.
          </p>
        </Zoom>
        <img
          className="h-[420px] w-[250px] translate-x-[-0.25rem] translate-y-[-1rem]"
          src="/images/page1-man.png"
          width={454}
          height={917}
          alt=""
        />
      </div>
    </div>
  );
};
export default Page1;
