import { LINKS } from "../../constants";

const Page13 = () => {
  return (
    <div className="page-common relative pb-8 pl-8 pr-8 bg-page-13">
      <div className="flex flex-col gap-1 items-center justify-center h-full font-galada">
        <div className="flex md:gap-[150px] gap-[50px]">
          <a href={LINKS.TWITTER} target="_blank" rel="noreferrer">
            <div className="cursor-pointer h-[70px] w-[70px] bg-x-icon common-transition hover:scale-105"></div>
          </a>
          <a href={LINKS.TELEGRAM} target="_blank" rel="noreferrer">
            <div className="cursor-pointer h-[70px] w-[70px]  bg-telegram-icon common-transition hover:scale-105"></div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Page13;
