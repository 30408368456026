/* eslint-disable jsx-a11y/alt-text */
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import AK47 from "../../images/AK-47.png"; // with import
import BulletSmall from "../../images/bullet-small.png"; // with import
import GlockGold from "../../images/glock-gold.png"; // with import

const Page10 = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="page-common relative p-8 bg-page-10">
      <div className="flex flex-col gap-1 justify-start h-full mt-[100px] relative z-10 ">
        <Fade
          cascade
          damping={0.5}
          className=" text-[32px] md:text-[42px] text-shadow font-galada text-white mt-4 md:mt-0"
        >
          Chapter 4
        </Fade>
        <Fade
          cascade
          damping={0.2}
          className=" text-3xl md:text-[42px] text-shadow font-galada text-white"
        >
          By the OG, For the OG
        </Fade>
        <div className="flex flex-col gap-4  mt-[20px] md:mt-[32px] min-h-[448px] relative">
          <img
            src={BulletSmall}
            className="w-[50px] h-[50px !import] md:w-[50px] md:h-[50px !import] absolute -top-10 md:top-[-60px] right-0 slide-right"
          />
          <div className="text-[18px] md:text-[24px] text-white leading-none">
            The Original Meme Godfather has morphed into a movement. Real OGs
            unitedly stand to defend the honor of crypto.
            <br />
            <br />
            The war is against evil policies that threaten the fundamental
            rights and freedom of the people. Unfairness in the rule of law that
            has caused the imprisonment of good people.
            <br />
            <br />
            The Meme Godfather has come to restore fairness and justice, uniting
            all the OGs to defend the crypto world.
          </div>
        </div>
      </div>
      <img
        src={AK47}
        className="w-[250px] h-[200px] md:w-[300px] md:h-[250px] absolute bottom-0 left-0 "
        data-aos="fade-left"
      />
      <img
        src={GlockGold}
        className="w-[200px] h-[150px] md:w-[250px] md:h-[200px] absolute bottom-0 right-0"
        data-aos="fade-right"
      />
    </div>
  );
};

export default Page10;
