import { Bounce, Fade, Hinge, Roll, Zoom } from "react-awesome-reveal";
import styles from "./First5Page.module.css";

const Page5 = () => {
  return (
    <div className={styles.page5__cover}>
      <div className="pt-14 relative z-10">
        <Fade cascade damping={0.05}>
          <p className={styles.page5__title}>
            Chapter 3
            <br />
            Godfather of Memes
          </p>
        </Fade>

        <img
          className="vibrate-2 absolute top-2 w-[200px] h-[130px] md:h-[150px] md:w-[250px] left-2"
          src="/images/shiba-1.png"
          alt=""
          width={380}
          height={215}
        />
      </div>
      <Bounce delay={800}>
        <div className="text-center text-white text-lg md:text-xl z-10 relative px-16 mt-6 md:mt-8 !leading-[1.1] font-squada">
          The discovery of the OG meme proves that memes ultimately started well
          before the existence of the world wide web
          <br />
          As the true pioneer of all memes OG has re-emerged to take his
          rightful place in the world of cryptocurrencies.
          <br />
          <br />
          Without of the birth of the Original Meme Godfather, none of the great
          meme coins like DOGE, PEPE or BONK would ever have been born
          <img
            className="flicker-2 absolute z-0  w-[100px] h-[100px] top-[-3.5rem] md:top-[-4rem] md:w-[120px] md:h-[120px] right-4"
            src="/images/doge.png"
            alt=""
          />
          <img
            className="jello-horizontal absolute z-0 h-[100px] w-[120px] md:w-[150px] left-0 top-48"
            src="/images/pepe.png"
            alt=""
          />
        </div>
      </Bounce>
      <Zoom delay={1500} duration={800}>
        <div className="relative z-10 text-center text-white text-xl leading-6 mt[20x] md:text-2xl font-bebas md:mt-6">
          All Hail the Meme King
          <br />
          All Hail the Original Meme Godfather
          <br />
          All Hail the OG!
        </div>
      </Zoom>
      <img
        className="absolute z-0 w-16 h-20 right-16 md:h-[88px] md:w-20 md:right-20 bottom-4"
        src="/images/head.png"
        alt=""
      />
    </div>
  );
};
export default Page5;
